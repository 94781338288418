.AboutMe {
    /* blah */
    position: absolute;
    height: 110vh;
    width: 100%;
    background-size: cover;
    color: white;
    font-size: 5vh;
    overflow: hidden;
    display: inline-block;
    display: flex;
    flex-direction: row, column;
}

.font {
    font-family: 'VT323';
}

.Title {
    position: absolute;
    margin-top: 5vh;
    margin-left: 0%;
    width: 100%;
    text-align: center;
    font-size: 10vh;
    font-family: 'Special Elite';
}
.Title:hover{
    animation: change2 8s infinite;
}
@keyframes change2 {
    0% {
      color: grey
    }
    25% {
      color: brown
    }
    50% {
      color: navajowhite
    }
    75% {
      color: #575655
    }
}

.ImageField{
    position:absolute;
    width: 30%;
    height:66%;
    margin-top: 20vh;
    float: left;
    background-size: cover;
}
.TextField{
    position:relative;
    width: 100%;
    height:70%;
    margin-right: 0;
    margin-top: 20vh;
    font-family: 'Special Elite';
    line-height: 1.5;
    padding-left: 10%;
    font-size: 3vh;
    color: white;
        /* border: red solid 1px; */

}


.Subtext {
    position: relative;
        /* border: red solid 1px; */

    margin-top: '20vh';
    margin-left: 0;
    font-size: 5vh;
    font-family: 'VT323';
}


.resume_button{
    position: fixed;
    color:aliceblue;
    display: inline-block;
    /* border: red solid 1px; */
    top: 0;
    right: 10%;

    width: 10%;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
}

.instagram_button{
    position: fixed;
    color:aliceblue;
    display: inline-block;
    right: 5px;
    width: 8%;
    top: 0;
    font-size: 1em;
    text-align: right;
    height:10vh;
    outline: none;
    border: none;
    text-decoration: none;
    /* border: red solid 1px; */

}
.return2{
    position: fixed;
    outline: none;
    border: none;

    left: 0;
    bottom: 0;
    width: 20vw;
    height: 10vh;
    text-align: left;
    font-size: 5vh;

    background-color: #ffffff00;
    color: aliceblue;
    font-family: 'Special Elite';
}
.return2:hover{
    cursor:pointer;
}

.video2{
    position: fixed;
    object-position: fixed;
    height: 100%;
    filter: grayscale() brightness(40%) ;
    
    z-index: 0;
}