/* Have to add the image */

.Home{
    position: fixed;
    
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;

    height: 100vh;
    width: 100vw;
    color:white;

    font-size: 5vh;
    background-size:cover;
    overflow:hidden;
    filter: brightness(90%);
    

}   

#Title {
    position:relative;
    width: '100%';
    font-size: 30vh;
    text-align:'center';
}

/* Used for fix header */
.header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0px 0;
    position: fixed;
    background-color: #ffffff00;

    /* Fixes the position */
    z-index:1;
}


li {
    cursor: pointer;
    list-style-type:none;
}



/*Used for fonts */
.fontSubtext {
    display:block;
    font-family: 'VT323';
    font-size: 20vh;    
    text-align: center;

}
.subtextSpace {
    position:relative;
    width: 100vw;
    height: 20vh;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
}
.subButtonSpace {
    position:relative;
    display: flex;
    width: 70%;
    height: 10vh;
    justify-content: center;
    align-items: center;  
    margin-left: auto;  
    margin-right: auto;
    margin-top: 0vh;
    
}

.buttonStyle {
    width: 7vw;
    height: 3vh;
    border:1px solid black;
}
.logo {
    width: 50px;
    height: 50px; 
}

.previousTrack {
    width: 15vw;
    height: 10vh;
    text-align: center;
    border-color: #ffffff00;
    font-size: 5vh;
    
    background-color: #ffffff00;
    color: bisque;
    font-family: 'VT323';
}
.previousTrack:hover{
    cursor:pointer;
    color: #656d4f;
}
.nextTrack:hover{
    cursor:pointer;
    color: #656d4f;
}


@keyframes change2 {
    0% {
        background-color: #FFFF00
      }
      25% {
        background-color: #FF0000
      }
      50% {
        background-color: #00FF00
      }
      75% {
        background-color: #00FFFF
      }
      90% {
        background-color: #FF00FF
      }
}

.nextTrack {
    /* position: absolute; */
    /* left: 20vw;
    bottom: 0; */
    width: 15vw;
    height: 10vh;
    text-align: center;
    border-color: #ffffff00;
    font-size: 5vh;
    
    background-color: #ffffff00;
    color: bisque;
    font-family: 'VT323';
}



.customImage {
    height: 10vh;
    width: 10vw;
    outline: none;
}
.customButton {
    background-size: 'cover';
    background-position: 'center'; 
    background-color: transparent;
    height: 10vh;
    width: 10vw;
}